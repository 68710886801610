<template>
  <div class="">
    <button class="button is-primary" @click.prevent="togglePanelHandle">
      <span class="icon"><unicon name="plus" fill="#fff" /></span>
       <span>New Issue</span>
    </button>

    <teleport to="body">
        <sidebar class="extended">
          <new-issue-panel :pid="pid" />
        </sidebar>
    </teleport>
  </div>
</template>

<script>
  import { defineAsyncComponent, reactive, toRefs, ref, toRef, readonly } from 'vue'
  import useGeneral from '@/use/useGeneral'

  export default {
    name: 'New Issue Button',
    props: {
      fullPath: {
        type: String,
        required: true
      }
    },

    components: {
      Sidebar: defineAsyncComponent(() => import('@/components/general/Sidebar')),
      NewIssuePanel: defineAsyncComponent(() => import('@/components/issues/new/NewIssuePanel'))
    },

    setup(props) {
      const pid = toRef(props, 'fullPath')

      const { setSidebarHandle } = useGeneral();

      const togglePanelHandle = () => {
        setSidebarHandle(true)
      }

      return {
        pid,
        togglePanelHandle
      }
    }
  }
</script>

<style lang="css" scoped>

</style>
